require("jquery")
require("./lib/jquery.cookie")
require("bootstrap")

import "../src/customer.scss"

window.addEventListener('DOMContentLoaded', (event) => {
  if ($('#showTermsOfUseModal').length > 0) {
    $('#showTermsOfUseModal,.showTermsOfUseModal').on('click', (e) => {
      $('body').addClass('terms-of-use')
      $('body').addClass($(e.target).prop('id'))
      $('#termsOfUseModal').modal('show')
    })
  }
});
